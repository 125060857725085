<template>
  <WheelStorage
    v-slot="{ wheels, status }"
    :filter="
      wheel => {
        return Number(wheel.status) < 8;
      }
    "
  >
    <SelfservicePageLayout :title="$route.meta.title">
      <template #controls>
        <TransferWheelstorageButton v-if="$isEnabled(Features.ACCOUNT_WHEELSTORAGE_TRANSFER)" />
      </template>
      <template #default>
        <div class="WheelStorageScene">
          <template v-if="status === 'FETCHED'">
            <div class="WheelStorageScene__list">
              <template v-if="wheels.length">
                <ul class="Wheels">
                  <li v-for="wheel in wheels" :key="wheel.id" class="Wheels__item">
                    <WheelCard :data="wheel" />
                  </li>
                </ul>
              </template>
            </div>

            <div class="WheelStorageScene__description">
              <div class="groupbodytext">
                <p>
                  <strong
                    >Der er mange fordele for&nbsp;dig ved at benytte&nbsp;vores professionelle
                    dækhotel:</strong
                  >
                </p>
                <p>- Vi opbevarer&nbsp;dine hjul på vores centrale dækhotel&nbsp;</p>
                <p>- Vi sikrer korrekt lagertemperatur for optimal holdbarhed af dækkene</p>
                <p>- thansen dækhotel er plads- og tidsbesparende for dig</p>
                <p>- Vi sender en påmindelse om tid til hjulskifte via SMS/e-mail</p>
                <p>
                  - Du kan nemt, og når du har tid, booke tid online&nbsp;til hjulskifte i alle
                  vores butikker
                </p>
                <p><strong>Pris: 399,- pr. sæt </strong>(kr. 99,75 pr. hjul)</p>
                <p>
                  OBS! Hvis du allerede har hjul i vores dækhotel, og blot ønsker at forlænge
                  opbevaringen i endnu en periode (8 mdr.), er prisen kun 299,- pr. sæt.&nbsp;
                </p>
                <p>&nbsp;&nbsp;</p>
                <p><strong>Sådan foregår det i praksis&nbsp;(STEP BY STEP):</strong></p>
                <ol>
                  <li>Dine hjul indleveres og&nbsp;registreres i vores dækhotel</li>
                  <li>
                    Alle relevante informationer gemmes, og thansen.dk sender&nbsp;kopi af disse
                    oplysninger til dig på SMS og e-mail
                  </li>
                  <li>
                    Dine hjul sendes til opbevaring centralt i et helt nyt og topmoderne dækhotel,
                    hvor vi sikrer optimale opbevaringsforhold for hjulene
                  </li>
                  <li>
                    Når vi nærmer os en ny sæson, vil du blive mindet om, at det er tid til
                    hjulskifte&nbsp;<br /><br /><br />
                  </li>
                </ol>
                <p>
                  <em>**En sæson&nbsp;</em><em>defineres&nbsp;</em
                  ><em>som sommer- eller vintersæson&nbsp;</em
                  ><em>(max. 8 måneders opbevaring).</em>
                </p>
                <p>
                  <em
                    >Prisen for opbevaring af hjul er betinget af skift af hjul eller dæk hos
                    thansen.<br /></em
                  ><em>Prisen er e</em><em>ksklusiv skift af hjul</em><em>/dæk.<br /></em
                  ><em>Pris for skift af hjul kr. 69,95</em><em>&nbsp;pr. stk.</em
                  ><em>&nbsp;Pris for skift af dæk fra kr. 99,95</em><em>&nbsp;pr. stk.<br /></em
                  ><em>Vi opbevarer ikke bolte/møtrikker.</em>
                </p>
              </div>
              <div class="WheelStorageScene__button">
                <Button :size="ButtonSizes.LARGE" type="cta" tag="a" href="/booking"
                  >Book tid her</Button
                >
              </div>
            </div>
          </template>
        </div>
      </template>
    </SelfservicePageLayout>
  </WheelStorage>
</template>

<script>
import WheelStorage from '@scenes/SelfserviceScene/containers/WheelStorage';
import WheelCard from './components/WheelStorageOverview/components/WheelCard2';
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';

import TransferWheelstorageButton from './components/TransferWheelstorageButton';
import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import Features from '@types/Features';

export default {
  name: 'WheelStorageScene',
  components: {
    WheelStorage,
    WheelCard,
    Button,
    TransferWheelstorageButton,
    SelfservicePageLayout,
  },
  enums: {
    ButtonSizes,
    ButtonColors,
    Features,
  },
};
</script>

<style lang="scss" scoped>
.WheelStorageScene {
  .WheelStorageScene__button {
    text-align: center;
  }
  .WheelStorageScene__list {
    margin-bottom: 40px;
  }
}
.Wheels {
  display: grid;

  width: 100%;

  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.Wheels--archive {
  opacity: 0.6;
}
.Wheels__item {
  display: block;
}
</style>
