import { getWheels } from '@services/thansen/wheelstorage';
import { Mutation, State } from './types';

export default {
  namespaced: true,
  state: {
    wheels: [],
    status: '',
  },
  getters: {
    getWheelById: state => id => {
      return state.wheels.find(wheel => wheel.id === id.toString());
    },
  },
  actions: {
    fetchWheels({ commit, state }) {
      //if (state.status === '') {
      commit(Mutation.SET_STATUS, State.FETCHING);

      getWheels()
        .then(wheels => {
          wheels = wheels.map(wheel => {
            return { ...wheel, status: Number(wheel.status) }; // TODO: Remove when API responds with an int
          });
          commit(Mutation.SET_WHEELS, wheels);
          commit(Mutation.SET_STATUS, State.FETCHED);
        })
        .catch(err => {
          commit(Mutation.SET_STATUS, State.ERROR);
        });
      //}
    },
  },
  mutations: {
    [Mutation.SET_STATUS](state, status) {
      state.status = status;
    },
    [Mutation.SET_WHEELS](state, wheels) {
      state.wheels = wheels;
    },
  },
};
