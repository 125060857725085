import WheelStorageModule from '@store/modules/wheelstorage';

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    filter: {
      type: Function,
    },
  },
  computed: {
    ...mapState('wheelstorage', {
      wheelsStatus: state => {
        return state.status;
      },
      wheels: state => state.wheels,
    }),
    filtredWheels() {
      if (this.filter !== undefined) {
        return this.wheels.filter(this.filter);
      } else {
        return this.wheels;
      }
    },
  },

  methods: {
    ...mapActions('wheelstorage', ['fetchWheels']),
  },

  created() {
    if (this.$store.state.wheelstorage === undefined) {
      this.$store.registerModule('wheelstorage', WheelStorageModule);
      this.fetchWheels();
    }
  },

  render() {
    return this.$scopedSlots.default({
      wheels: this.filtredWheels,
      status: this.wheelsStatus,
    });
  },
};
