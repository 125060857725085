<template>
  <div class="Page">
    <slot name="header">
      <div :class="['Page__header', hideHeaderOnPrint && 'Page__header--hideOnPrint']">
        <h1 class="Page__heading ft-h2">{{ title || $route.meta.title }}</h1>

        <div class="SelfservicePage__headerControls">
          <slot name="controls" />
        </div>
      </div>
    </slot>
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: 'SelfservicePageLayout',
  components: {},

  props: {
    title: {
      default: '',
      type: String,
    },
    hideHeaderOnPrint: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.Page {
  padding: 0 20px;

  @media (min-width: $tablet) {
    padding: 0;
  }

  .Page__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@mixin printStyles() {
  .Page {
    .Page__header {
      &--hideOnPrint {
        display: none;
      }
    }
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>

<style lang="scss" scoped>
.SelfservicePage__headerControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-left: 10px;
  }
}
</style>
