export const Mutation = {
  SET_WHEELS: 'SET_WHEELS',
  SET_STATUS: 'SET_STATUS',
};

export const Actions = {};

export const State = {
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  ERROR: 'ERROR',
};
