<template>
  <div class="TransferWheelstorageButton">
    <Button type="light" icon="far fa-exchange" @click="$refs.modal.open()">
      {{ $t('wheel_storage.transfer.control') }}
    </Button>
    <Validator v-slot="{ valid, showError }" ref="validator">
      <Modal ref="modal" :title="$t('wheel_storage.transfer.control')" :working="working">
        <template #default>
          <p>{{ $t('wheel_storage.transfer.description') }}</p>
          <Form
            class="TransferWheelstorageButton__form"
            @submit="valid ? submit() : showError()"
            :locked="working"
          >
            <template #default>
              <FadeTransition :duration="0.2" :delay="0" transform-height>
                <Alert hideIcon v-if="success" :level="AlertLevel.SUCCESS">
                  {{ $t('wheel_storage.transfer.message.success') }}
                </Alert>
                <Alert
                  hideIcon
                  v-else-if="error === 'unknown_combination'"
                  :level="AlertLevel.WARNING"
                >
                  {{ $t('wheel_storage.transfer.message.not_found') }}
                </Alert>
                <Alert v-else-if="error" :level="AlertLevel.ERROR">
                  {{ $t('error_messages.try_again') }}
                </Alert>
              </FadeTransition>
              <Input
                id="storageId"
                name="storageId"
                type="text"
                :label="$t('wheel_storage.storage_id')"
                required
                pattern="^\d*$"
                :patternErrorMessage="'Opbevarings nr. må kun indeholde tal'"
                validateOnBlur
                v-model="inputs.storageId"
              />
              <Input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                :label="$t('wheel_storage.mobile_number')"
                required
                v-model="inputs.phoneNumber"
              />
            </template>
          </Form>
        </template>
        <template #controls="{ close }">
          <Button :color="ButtonColors.WHITE" @click.stop="close">{{ $t('cancel') }}</Button>
          <Button @click="valid ? submit() : showError()">{{
            $t('wheel_storage.transfer.submit')
          }}</Button>
        </template>
      </Modal>
    </Validator>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes, colors as ButtonColors } from '@components/Buttons';
import { Modal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { postTransfer } from '@services/thansen/wheelstorage';

import Alert, { AlertLevel } from '@components/Alert';

import Validator from '@containers/Validator';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';
import Form from '@layouts/Form';
import FadeTransition from '@transitions/Fade';

export default {
  name: 'TransferWheelstorageButton',

  components: {
    Modal,
    StoreFinder,
    Button,
    Alert,
    Form,
    Input,
    Validator,
    FadeTransition,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    AlertLevel,
    Autocompletes,
  },

  props: {},

  data() {
    return {
      working: false,
      branch: null,
      error: '',
      success: false,
      inputs: {
        storageId: '',
        phoneNumber: '',
        orderNumber: '',
      },
    };
  },

  computed: {},
  watch: {
    'inputs.storageId'() {
      this.clearError();
    },
    'inputs.phoneNumber'() {
      this.clearError();
    },
  },
  methods: {
    submit() {
      this.working = true;
      this.success = false;

      postTransfer({
        storage_id: Number(this.inputs.storageId),
        phone_number: this.inputs.phoneNumber,
      })
        .then(response => {
          this.clearInputs();
          this.success = true;
          this.working = false;
        })
        .catch(errors => {
          if (Array.isArray(errors) && errors.length) {
            this.error = errors[0].code;
          } else {
            this.error = 'error';
          }
          this.working = false;
        });
    },
    clearInputs() {
      this.inputs.storageId = '';
      this.inputs.phoneNumber = '';
      this.inputs.orderNumber = '';
    },
    clearError() {
      this.error = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.TransferWheelstorageButton {
  .TransferWheelstorageButton__storefinder {
    min-height: 300px;
    height: 40vh;
  }

  .TransferWheelstorageButton__headline {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1em;
  }
}
</style>
